.payment-modal .payment-list {
  margin-top: 24px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.payment-modal .total {
  text-align: right;
  margin-bottom: 15px;
  padding-right: 10px;
}

.payment-modal .total > span {
  margin-left: 15px;
}
