.dashboard-subscription-item {
  padding: 25px 23px 13px 23px;
  border-radius: 10px;
  border: 1px solid var(--theme-primary-navy);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-subscription-item .title {
  font-weight: 500;
  font-size: 22px;
}

.dashboard-subscription-item .description {
  color: #516e73;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-subscription-item .status {
  margin-top: 22px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
}

.dashboard-subscription-item .status.-active {
  color: #05BB6f;
}
