.dashboard-profile-card {
  padding: 25px 23px;
  border: 1px solid var(--theme-primary-navy);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

.dashboard-profile-card .title {
  font-weight: 500;
  font-size: 20px;
}

.dashboard-profile-card .subtitle {
  color: #516e73;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-profile-card.-complete {
  border-color: #15bf7b;
}

.profile-card-complete-tick {
  display: flex;
  color: #05bb6f;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
}

.profile-card-complete-tick .tick {
  margin-right: 8px;
  height: 38px;
  width: 38px;
}

.dashboard-profile-card .bottom {
  margin-top: 14px;
  display: flex;
}

