.confirm-subscription .total-display {
  display: flex;
  align-items: center;
}

.confirm-subscription .total {
  margin-bottom: 24px;
}

.confirm-subscription .page-heading {
  margin-bottom: 24px;
}

.confirm-subscription .totaldisplay {
  background: var(--rs-sidenav-default-bg);
  padding: 10px;
  width: 205px;
  height: 145px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
}

.confirm-subscription .totaldisplay .totallabel {
  opacity: 0.5;
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.confirm-subscription .totaldisplay .fee {
  font-size: 12px;
  line-height: 1.1;
  padding: 0 10px;
}

.confirm-subscription .location {
  margin-bottom: 12px;
}

.confirm-subscription .form {
  margin-top: 16px;
}

.confirm-subscription .section {
  margin-bottom: 32px;
}

.confirm-subscription .sectiontitle {
  font-size: 22px;
  margin-bottom: 8px;
}
