.kpm-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--theme-primary-navy);
    overflow: hidden;
    margin: 5px 50px;
  }
  
  .kpm-item .body {
    flex-grow: 1;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }
  
  .kpm-item .footer {
    padding: 15px 32px;
    background: var(--theme-primary-navy);
    color: white;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  