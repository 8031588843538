:root {
  /* Theme colours */
  --theme-primary-navy: #101617;
  --theme-primary-cyan: #61f1e3;


  /* Element colours */
  --theme-border-primary: #eaeaea;
  --theme-text-primary: #212121;
  --theme-text-primary-inverted: #ffffff;
  --theme-text-secondary-inverted: #bebedc;
  --theme-bg-primary: #f6f6f6;
  --theme-bg-primary-inverted: var(--theme-primary-navy);
  --theme-btn-primary-hover: #43ddd8;
  --theme-btn-subtle-hover: #00000008;

  /* Override Rsuite variables */
  --rs-btn-primary-bg: var(--theme-primary-cyan);
  --rs-btn-primary-active-bg: var(--theme-btn-primary-hover);
  --rs-btn-primary-text: var(--theme-text-primary);
  --rs-navbar-inverse-bg: var(--theme-primary-navy);
  --rs-border-primary: var(--theme-border-primary);
  --rs-text-primary: var(--theme-text-primary);
  --rs-input-focus-border: var(--theme-primary-cyan);
  --rs-sidenav-default-bg: var(--theme-bg-primary);
  --rs-sidenav-default-selected-text: var(--theme-text-primary-inverted);
}

.rs-btn-primary:hover {
  background-color: var(--theme-btn-primary-hover);
}

.rs-btn-subtle:hover {
  background-color: var(--theme-btn-subtle-hover);
}
