.noover-logo .text,
.noover-logo .background,
.noover-logo.-inverted .foreground {
  fill: #101617;
}

.noover-logo .foreground,
.noover-logo.-inverted .text,
.noover-logo.-inverted .background {
  fill: var(--theme-primary-cyan);
}
