.customer-dashboard {
  max-width: 1000px;
  padding: 42px;
}

.customer-dashboard .casualbanner {
  display:flex;
  margin-bottom: 32px;
}

.casual-parking-banner {
  position: relative;
  padding: 10px 33px 10px 55px;
  border-radius: 14px;
  background: var(--theme-primary-navy);
}

.casual-parking-banner .post {
  position: absolute;
  top: -28px;
  left: 2px;
}

.casual-parking-banner .title {
  color: var(--theme-primary-cyan);
  font-weight: 600;
  font-size: 16px;
}

.casual-parking-banner .alternative {
  color: #cacecf;
  margin-left: 4px;
}

.casual-parking-banner .alternative a {
  color: #cacecf;
  font-weight: 700;
}

.dashboard-section {
  margin-top: 45px;
}

@media (max-width: 800px) {
  .customer-dashboard .dashboard-banner {
    margin-top: 48px;
  }
}
