.cta-button {
  background: var(--theme-primary-navy);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: var(--theme-primary-cyan);
}

.cta-button.-invert {
  background: #61F1E3;
  color: var(--theme-primary-navy)
}

.cta-button:hover {
  /* This needs design direction */
  background: #394e52;
}

.cta-button.-large {
  padding: 13px 30px;
  border-radius: 26px;
  font-size: 20px;
}

.cta-button.-large.-icon .label {
  margin-left: 13px;
}

.cta-button.-large .icon {
  transform: translateY(-1px);
}

.cta-button.-med {
  padding: 7px 13px;
  border-radius: 26px;
  font-size: 14px;
}

.cta-button.-med.-icon .label {
  margin-left: 6px;
}

.cta-button.-med .icon {
  transform: translateY(-1.5px);
}
