.noover-invoice .invoice-header {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.noover-invoice .details {
  margin-top: 18px;
  text-align: right;
}

.noover-invoice .summary {
  margin-top: 24px;
  text-align: right;
}

.noover-invoice .items {
  margin-top: 16px;
  margin-bottom: 16px;
}

.noover-invoice .cancelled {
  margin-top: 18px;
  font-size: 12px;
  text-transform: uppercase;
  color: green;
}

.noover-invoice table {
  width: 100%;
  text-align: left;
  table-layout: fixed;
  border-collapse: collapse;
}

.noover-invoice th {
  padding: 16px;
}

.noover-invoice td {
  padding: 16px;
  vertical-align: baseline;
}

.noover-invoice tbody tr {
  border-top: 1px solid var(--theme-btn-subtle-hover);
}

.noover-invoice .printbutton {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
}

@media (max-width: 800px) {
  .noover-invoice .invoice-header {
    margin-top: 64px;
  }
}

@media print {
  .noover-invoice {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 100vw;
    height: 100vh;
  }

  .noover-invoice .printbutton {
    display: none;
  }

  .noover-invoice .invoice-header {
    margin-top: 16px;
  }
}
