.sidebar-toggle {
  display: none;
}

.rs-container {
  position: relative;
}

@media (max-width: 800px) {
  .sidebar-toggle {
    display: initial;
  }

  .rs-sidebar:not(.-expanded) {
    left: calc(-1 * var(--sidebar-width));
  }

  .rs-sidebar {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left .3s;
  }

  .rs-sidebar + main {
    z-index: 0;
  }

  .rs-panel-header h2 {
    margin-left: 45px;
  }
}

.sidebar-toggle.-open {
  position: absolute !important;
  top: 22px;
  left: 15px;
  z-index: 1;
}

