.card-grid {
  display: flex;
  flex-wrap: wrap;
}

.card-grid .card {
  width: 180px;
  margin-bottom: 15px;
}

.card-grid .card.-span-2 {
  width: 375px;
}

.card-grid .card:not(:last-child) {
  margin-right: 15px;
}
