
.rego-list {
  width: inherit;
}

.rego-list .group {
  display: flex;
}

.rego-list .rs-form-group:not(:first-child) .rs-form-control-label {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.rego-list .group > *:not(.rs-btn-icon) {
  width: auto;
  flex: 1;
}

.rego-list .group > *:not(:first-child) {
  margin-left: 10px;
}

.rego-list .group .rs-btn-icon {
  margin-top: auto;
  width: 38px;
  height: 38px;
}
