.user-list .search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.user-list .pagination {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.user-list .search-bar {
  margin-bottom: 16px;
}

.user-list .flag-filter {
  margin-bottom: 16px;
  width: 300px;
  margin-right: 16px;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-bar .input {
  width: 200px;
  margin-right: 12px;
}

.search-bar .input input{
  margin-top: 4px;
}

.search-bar .button button{
  margin-top: 24px;
}

.flag-filter .rs-picker{
  margin-top: 4px;
}

.user-list .rs-tag-group{
  margin: 0
}

.user-list .rs-tag-group > .rs-tag{
  margin-left: 0px; 
  margin-top: 0px;
}

 