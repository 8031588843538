.card {
  max-width: 500px;
  min-height: 250px;
  position: relative;
}

.card .rs-panel-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card .rs-panel-body > * {
  flex-shrink: 1;
}

.card.-centred .rs-panel-body {
  justify-content: center;
  align-items: center;
}

.card p {
  margin-bottom: 10px;
}

.card.-centred p {
  text-align: center;
}

.card:not(.-centred) .rs-btn-toolbar {
  justify-self: flex-end;
  display: flex;
  margin-top: auto;
}

.card:not(.-centred) .rs-btn-toolbar .rs-btn {
  flex: 1;
  margin: 0;
  border-radius: 0;
}

.card .rs-loader-wrapper {
  z-index: 1;
}
