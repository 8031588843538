:root {
  --sidebar-width: 300px;
}

.rs-container.rs-container-has-sidebar > .rs-sidebar {
  /* Override Rsuite inline styles */
  flex: 0 0 var(--sidebar-width) !important;
  width: var(--sidebar-width) !important;
}

.side-menu.rs-sidenav-default {
  height: 100%;
  width: var(--sidebar-width);
  position: relative;
}

.side-menu .rs-sidenav-body {
  padding: 30px;
}

.side-menu .rs-sidenav-nav > .rs-sidenav-item {
  /* Override Rsuite important property */
  margin-bottom: 10px !important;
  border-radius: 8px;
}

.side-menu .rs-sidenav-item.rs-sidenav-item-active, .rs-dropdown-selected-within > a {
  background: var(--theme-bg-primary-inverted) !important;
}

.side-menu .rs-dropdown-selected-within > a {
  border-radius: 8px;
  color: #fff;
}

.side-menu .rs-dropdown-selected-within svg {
  fill: var(--theme-primary-cyan) !important;
}

.side-menu .rs-dropdown-item-active {
  text-decoration: underline !important;
  font-weight: 600 !important;
  color: rgb(87, 87, 87) !important;
}

.side-menu .rs-sidenav-item {
  padding-left: 60px !important;
}

.side-menu .rs-sidenav-item.rs-sidenav-item-active svg {
  fill: var(--theme-primary-cyan);  
}

.side-menu .noover-logo {
  margin-left: 10px;
  margin-bottom: 25px;
}

.side-menu .sidebar-toggle.-close {
  position: absolute;
  right: 20px;
  top: 33px;
}

.side-menu .version-info {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.rs-sidenav-nav > .rs-dropdown > .rs-sidenav-item svg {
  color: inherit;
  fill: rgb(87, 87, 87);
}

.rs-sidenav-nav > .rs-dropdown > .rs-dropdown-menu  {
  color: rgb(87, 87, 87);
}

.rs-sidenav-nav .rs-dropdown-item {
  margin-left: 4px !important;
}

/* Must use id for submenu bottom margin due to rsuite highly specific margin 0 !important  */
#reporting-submenu { 
  margin-bottom: 10px !important;
}

@media print {
  .side-menu {
    display: none;
  }
}