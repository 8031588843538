.login-page {
  display: flex;
  flex-direction: row;
  margin: 50px;
  --border-radius: 10px;
  background: var(--theme-bg-primary);
  border: 1px solid var(--theme-border-primary);
  border-radius: var(--border-radius);
}

.login-page .welcome {
  background: var(--theme-bg-primary-inverted);
  color: var(--theme-text-primary-inverted);
  padding: 50px;
  max-width: 400px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.login-page .welcome img {
  width: 100%;
  margin-bottom: 50px;
  margin-top: -85px;
}

.login-page .form {
  padding: 20px 30px 15px;
  width: 380px;
}

.login-page .form.-signup {
  width: auto;
  max-width: 100%;
  padding: 50px;
}

.login-page .form.-signup {
  padding: 50px 80px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.login-page .form.-login {
  padding-top: 35px;
  padding-bottom: 10px;
}

.login-page .form.-login .noover-logo {
  display: flex;
  justify-content: center;
  margin-left: -20px;
}

.login-page .form.-signup .rs-panel {
  width: 350px;
}

.login-page .form .rs-panel-header {
  font-size: 25px;
  text-align: center;
  display: block;
}

.login-page .form p {
  text-align: center;
}

.login-page .form.-login .rs-btn-primary,
.login-page .form.-signup .rs-btn-primary {
  margin: auto;
  padding: 12px 25px;
  width: 170px;
  display: block;
}

.login-page .form.-signup .rs-btn-primary {
  margin-top: 40px;
}

.login-page .form.-confirm .rs-btn-primary {
  /* Squeeze it in the available space */
  padding-left: 12px;
  padding-right: 12px;
}

.login-page form + p {
  margin-top: 20px;
}

.login-page ul {
  padding-left: 25px;
}

.login-page ul li {
  list-style-type: none;
  position: relative;
  margin-top: 30px;
}

.login-page ul li h2 {
  font-size: 15px;
  font-weight: inherit;
  line-height: inherit;
}

.login-page ul li p {
  color: var(--theme-text-secondary-inverted);
}

.login-page ul li::before {
  content: '';
  position: absolute;
  right: 100%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 100%;
  background: var(--theme-primary-cyan);
}

.login-page ul li::after {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 8px;
  height: 5px;
  margin-right: 13px;
  margin-top: 6px;
  border: 2px solid var(--theme-primary-navy);
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

.login-page .mobilewelcomelist {
  display: none;
}

@media (max-width: 900px) {
  .login-page {
    flex-direction: column;
    margin: 0;
    border-radius: 0;
  }

  .login-page .welcome {
    max-width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .login-page .welcome ul {
    display: none;
  }

  .login-page .welcome img {
    height: 170px;
    margin-bottom: 0;
    margin-top: 0px;
  }

  .login-page .form.-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }

  .login-page .mobilewelcomelist {
    display: flex;
    justify-content: center;
    background: white;
    padding: 0px;
  }

  .login-page  .rs-panel-title {
    margin: 0px !important;
  }

  .login-page ul li p {
    color: var(--rs-gray-700);
  }

  .login-page ul li::after {
    border: 2px solid white;
    border-top: 0;
    border-right: 0;
  }
}



