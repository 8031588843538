.dashboard-banner {
  background: #19d8c8;
  border-radius: 20px;
  padding: 35px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.dashboard-banner .left {
  max-width: 300px;
}

.banner-description .title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
}

.banner-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-cta .description {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 6px;
}

@media (max-width: 1100px) {
  .dashboard-banner {
    flex-direction: column;
    align-items: center;
  }

  .banner-description {
    text-align: center;
  }

  .banner-cta {
    margin-top: 32px;
  }
}
