.error-page {
  max-width: 500px;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
}

.error-page .rs-panel-body > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-page .rs-icon {
  width: 3em;
  height: 3em;
  margin-right: .8em;
}

.error-page.-error .rs-icon {
  fill: var(--rs-state-error);
}

.error-page.-warning .rs-icon {
  fill: var(--rs-state-warning);
}

.error-page.-info .rs-icon {
  fill:  var(--rs-state-info);
}

.error-page.-small h1 {
  font-size: 25px;
  margin-bottom: -5px;
}
