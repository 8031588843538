.subscription-list {
  width: 100%;
}

.subscription-list .intro {
  margin-bottom: 24px;
}

.subscription-list .table {
  margin-bottom: 24px;
}

.subscription-list .rs-table {
  min-width: 900px;
}
