.sidebar-toggle {
  width: 45px;
  height: 45px;
}

.sidebar-toggle button,
.sidebar-toggle svg {
  width: 100%;
  height: 100%;
  margin: 0;
}
