.create-subscription .loader {
  margin: 32px;
}

.create-subscription .instruction {
  margin-bottom: 16px;
}

.section-title {
  font-size: 20px;
  margin-bottom: 8px;
}
