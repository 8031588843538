.alerts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: none;
}

.alerts.-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
}

.alerts .rs-message {
  max-width: 700px;
  pointer-events: initial;
  margin-top: 20px;
}

.alerts .rs-message.rs-message-info {
  border: 1px solid #2196f355;
}

.alerts .rs-message.rs-message-success {
  border: 1px solid #4caf5055;
}

.alerts .rs-message.rs-message-warning {
  border: 1px solid #ffb30055;
}

.alerts .rs-message.rs-message-error {
  border: 1px solid #f4433655;
}

.alerts .rs-message-header {
  font-size: 110%;
}
