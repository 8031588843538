.location-list .pagination {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.location-occupancy-tag {
  min-width: 48px; 
  text-align: center;
}

