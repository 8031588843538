.customer-visits-list .pagination {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}


.customer-visits-list .display-month {
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
