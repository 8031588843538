.dashboard-new-item {
  color: white;
  background: var(--theme-primary-navy);
  display: flex;
  padding: 32px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}

.dashboard-new-item .description {
  font-weight: 500;
  font-size: 13px;
  padding: 0 50px;
  margin-bottom: 10px;
}
