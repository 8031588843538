.header-bar.rs-navbar {
  background: none;
  padding: 5px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.header-bar .rs-dropdown-toggle-icon {
  width: 1.5em;
  height: 1.5em;
}

.header-bar .rs-navbar-item {
  padding: 5px 8px;
  height: auto;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .header-bar .rs-navbar-item .label {
    display: inline-block;
    overflow: hidden;
    width: 0;
    height: 0;
    margin: 0;
  }

  .header-bar .rs-navbar-item svg {
    margin-right: 0;
  }
}
